<!-- 角色 -->

<template>
	<div style="height: 100%">
		<a-row :gutter="24" type="flex" align="stretch" style="height: 100%">
			<a-col :span="24" class="mb-24">
				<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: '24px' }">
					<template #title>
						<a-row type="flex" align="middle" justify="space-between">
							<a-col :span="24" :md="4">
								<h5 class="font-semibold m-0">角色分类</h5>
							</a-col>
							<a-col :span="24" :md="20" style="display: flex; align-items: center; justify-content: flex-end">
								<a-radio-group v-model="classifySelected" size="small" @change="getSecondClassify">
									<a-radio-button :value="item.id" v-for="(item, index) in classifyData" :key="'classify_' + item.id">{{ item.title }}</a-radio-button>
								</a-radio-group>
							</a-col>
						</a-row>
					</template>

					<div v-for="(item, index) in classifyData" :key="'first_' + item.id">
						<a-row :gutter="[24, 24]" v-if="item.id == classifySelected">
							<a-col :span="24" :sm="12" :md="8" :lg="8" :xl="6" v-for="(item1, index1) in item.children" :key="'second_' + item1.id" @click="classifyItem(item1.id)">
								<div class="footer_box">
									<span class="icon">
										<img :src="item1.image" alt="" />
									</span>
									<h6>{{ item1.title }}</h6>
									<p class="c-line-2">{{ item1.desc }}</p>
									<div class="collect" v-if="token" @click.stop="collectWriteItem(item.id, item1.id, item1.isVote)">
										<a-icon type="star" theme="filled" :style="{ fontSize: '18px', color: item1.isVote == 0 ? '#333' : '#1890ff', display: 'block' }" />
									</div>
								</div>
							</a-col>
						</a-row>
					</div>
				</a-card>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		components: {},
		data() {
			return {
				classifyData: [],
				classifySelected: ""
			}
		},
		computed: {
			...mapGetters("user", ["token"])
		},
		created() {
			this.getFirstClassify()
		},
		methods: {
			// 获取收藏分类
			getCollectClassify() {
				this.$http("role.collectList").then(res => {
					if (res.code === 1) {
						let arr = []
						res.data.map(item => {
							const {
								prompt: { id, title, desc, image, isVote }
							} = item
							arr.push({ id, title, desc, image, isVote })
						})
						this.classifyData.map(item => {
							if (item.id === "collect") {
								item.children = arr
							}
						})
					}
				})
			},
			// 收藏
			collectWriteItem(pid, cid, vote) {
				this.$http("role.collect", { prompt_id: cid }).then(res => {
					if (res.code === 1) {
						this.classifyData.map(item => {
							if (item.id == pid) {
								item.children.map(item1 => {
									if (item1.id == cid) {
										item1.isVote = vote == 0 ? 1 : 0
									}
								})
							}
						})
						this.$message.success(res.msg)
					}
				})
			},
			// 进入当前分类
			classifyItem(id) {
				console.log(id, "id")
				this.$router.push("/role/chat?id=" + id)
			},
			// 获取一级分类
			getFirstClassify() {
				this.$http("role.first").then(res => {
					if (res.code === 1 && res.data.length) {
						let arr = []
						res.data.map((item, index) => {
							arr.push({
								id: item.id,
								title: item.title,
								children: []
							})
						})
						this.classifyData = arr
						this.classifySelected = arr[0].id
						this.getSecondClassify()
						if (this.token) {
							this.classifyData.unshift({
								id: "collect",
								title: "收藏",
								children: []
							})
							this.getCollectClassify()
						}
					}
				})
			},
			// 获取二级分类
			getSecondClassify() {
				if (this.classifySelected === "collect" && this.token) {
					this.getCollectClassify()
					return
				}
				this.$http("role.second", { topic_id: this.classifySelected }).then(res => {
					if (res.code === 1) {
						this.classifyData.map((item, index) => {
							if (item.id == this.classifySelected) {
								item.children = res.data.map(({ id, title, desc, image, isVote }) => ({ id, title, desc, image, isVote }))
							}
						})
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.footer_box {
		padding: 12px;
		box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.05);
		border-radius: 8px;
		color: #000;
		cursor: pointer;
		position: relative;
		border: 1px solid transparent;

		&:hover {
			border-color: #1890ff;
			box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
		}

		.icon {
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;
			overflow: hidden;

			svg path {
				fill: #1890ff;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		h6 {
			color: #000;
		}

		p {
			margin-bottom: 0;
			line-height: 20px;
			height: 40px;
		}

		.collect {
			position: absolute;
			top: 18px;
			right: 12px;
		}
	}
</style>
